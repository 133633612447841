// const ENV = 'local'
// const ENV = 'development'
// const ENV = 'master'

const REACT_APP_ENV = process.env.REACT_APP_ENV
// console.log("🚀 ~ file: constants.js ~ line 6 ~ REACT_APP_ENV", REACT_APP_ENV)

var BASEURL = '';
var BASENAME = '';
var API_BASEURL = '';

var AWS_CONFING = {}

var FILE_PREFIX_CODE = "255";

switch (REACT_APP_ENV) {
    case 'local':

        BASEURL = process.env.PUBLIC_URL;
        BASENAME = process.env.REACT_APP_ROUTE_FLAG;
        API_BASEURL = "https://digicase-web-api.apps.openxcell.dev/digicase-api/cms";
        // API_BASEURL = "http://192.168.2.45:3002/digicase-api/cms";

        AWS_CONFING.AWS_S3_BUCKET = 'openxcell-development-public'
        AWS_CONFING.AWS_S3_ACCESSKEYID = 'AKIAW4UEQAQLQT6PW47M'
        AWS_CONFING.AWS_S3_SECRETACCESSKEY = '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'
        AWS_CONFING.AWS_S3_REGION = 'ap-south-1'

        AWS_CONFING.AWS_S3_URL = 'https://openxcell-development-public.s3.ap-south-1.amazonaws.com/'

        AWS_CONFING.FOLDER_ADD = 'digicase/temp'

        AWS_CONFING.USERS_FOLDER = 'digicase/users/'
        AWS_CONFING.ASSETS_CMS_FOLDER = 'digicase/assets/cms/'

        break;

    case 'development':

        BASEURL = process.env.PUBLIC_URL;
        BASENAME = process.env.REACT_APP_ROUTE_FLAG;
        API_BASEURL = "https://digicase-web-api.apps.openxcell.dev/digicase-api/cms";

        AWS_CONFING.AWS_S3_BUCKET = 'openxcell-development-public'
        AWS_CONFING.AWS_S3_ACCESSKEYID = 'AKIAW4UEQAQLQT6PW47M'
        AWS_CONFING.AWS_S3_SECRETACCESSKEY = '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'
        AWS_CONFING.AWS_S3_REGION = 'ap-south-1'

        AWS_CONFING.AWS_S3_URL = 'https://openxcell-development-public.s3.ap-south-1.amazonaws.com/'

        AWS_CONFING.FOLDER_ADD = 'digicase/temp'

        AWS_CONFING.USERS_FOLDER = 'digicase/users/'
        AWS_CONFING.ASSETS_CMS_FOLDER = 'digicase/assets/cms/'

        break;

    case 'staging':

        BASEURL = process.env.PUBLIC_URL;
        BASENAME = process.env.REACT_APP_ROUTE_FLAG;
        API_BASEURL = "https://stagingapi.digicase.app/digicase-api/cms";

        AWS_CONFING.AWS_S3_BUCKET = 'digicase-staging'
        AWS_CONFING.AWS_S3_ACCESSKEYID = 'AKIAQ5SRCYYIMI6CEM76'
        AWS_CONFING.AWS_S3_SECRETACCESSKEY = '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'
        AWS_CONFING.AWS_S3_REGION = 'eu-central-1'

        AWS_CONFING.AWS_S3_URL = 'https://digicase-staging.s3.eu-central-1.amazonaws.com/'

        AWS_CONFING.FOLDER_ADD = 'digicase/temp'

        AWS_CONFING.USERS_FOLDER = 'digicase/users/'
        AWS_CONFING.ASSETS_CMS_FOLDER = 'digicase/assets/cms/'

        break;

    case 'master':

        BASEURL = process.env.PUBLIC_URL;
        BASENAME = process.env.REACT_APP_ROUTE_FLAG;
        API_BASEURL = "https://api.digicase.app/digicase-api/cms";

        AWS_CONFING.AWS_S3_BUCKET = 'digicase-public'
        AWS_CONFING.AWS_S3_ACCESSKEYID = 'AKIAQ5SRCYYINU3W3YXH'
        AWS_CONFING.AWS_S3_SECRETACCESSKEY = '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'
        AWS_CONFING.AWS_S3_REGION = 'eu-central-1'

        AWS_CONFING.AWS_S3_URL = 'https://digicase-public.s3.eu-central-1.amazonaws.com/'

        AWS_CONFING.FOLDER_ADD = 'digicase/temp'

        AWS_CONFING.USERS_FOLDER = 'digicase/users/'
        AWS_CONFING.ASSETS_CMS_FOLDER = 'digicase/assets/cms/'

        break;

    default:
        break;
}

export {
    BASEURL,
    BASENAME,
    AWS_CONFING,
    API_BASEURL,
    FILE_PREFIX_CODE
}
